// Import dependencies
import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { fullEntryContent } from '../queries'; // eslint-disable-line

// Import Components
import CmsBlocks from '../../../app/components/CmsBlocks';
import LayoutMarketing from '../../../app/components/LayoutMarketing';

const ContactPage = ({
  data: {
    craft: { entry, globals },
  },
  ...props
}) => {
  return (
    <LayoutMarketing
      hero={{ ...((entry?.richContentHero && entry?.richContentHero[0]) || {}), simple: true }}
      title={entry?.title}
      footerLinks={globals?.footer?.footer}
      logo={globals?.general?.logo?.url}
      navItems={globals?.general?.navigationLinks}
      loginUrl={globals?.general?.loginUrl}
      getStartedUrl={globals?.general?.getStartedUrl}
      {...props}
    >
      <CmsBlocks blocks={entry.contactBlock} test={entry} />
    </LayoutMarketing>
  );
};

ContactPage.propTypes = {
  data: PropTypes.shape({}).isRequired,
};

export const pageQuery = graphql`
  {
    craft {
      entry(id: 793) {
        ...contactPage
      }
      globals {
        footer {
          footer {
            ...footer
          }
        }
        general {
          logo {
            url
          }
          navigationLinks {
            ...nav
          }
          loginUrl
          getStartedUrl
        }
      }
    }
  }
`;

export default ContactPage;
